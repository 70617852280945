<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <b-img :src="require('@/assets/images/logo/logo-sm.png')" alt="logo" />

          <h2 class="brand-text text-primary ml-1">
            iq.kaeltepartner.net
          </h2>
        </b-link>

        <b-card-text class="mb-2">
          We've emailed you instructions for setting your password, if an account exists with the email you entered.
          You should receive them shortly.
        </b-card-text>
        <b-card-text class="mb-2">
          If you don't receive an email, please make sure you've entered the address you registered with,
          and check your spam folder.
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BImg,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
